<template>
  <div>
    <head-layout  :head-title="$t('cip.dc.DataAggregation.title.AddDataAggregationconfiguration')"
                  :head-btn-options="headBtnOptions"
                  @head-save="headSave('save')"
                  @head-save-back="headSave('back')"
                  @head-cancel="headCancel"
    ></head-layout>
    <div class="newZtFormBox" style="background-color: white;margin-top: 10px;">
      <el-form :inline="true" :model="form" label-width="120px" :rules="rules" ref="ruleForm">
        <el-row>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.DataAggregation.field.AggregationCoding')" prop="aggCode">
              <el-input :placeholder = "$t('cip.dc.DataAggregation.field.AggregationCoding')"  class = 'new-form-input' v-model="form.aggCode" size="mini" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.DataAggregation.field.Taskgrouping')" prop="taskGroup">
              <el-input   :placeholder = "$t('cip.dc.DataAggregation.field.Taskgrouping')" class = 'new-form-input' v-model="form.taskGroup" size="mini" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.DataAggregation.field.Executionorder')" prop="taskExecuteOrder">
              <el-input  :placeholder = "$t('cip.dc.DataAggregation.field.Executionorder')" class = 'new-form-input' v-model="form.taskExecuteOrder" size="mini" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.DataAggregation.field.Aggregationsourcetable')" prop="sourceTable">
              <el-input   :placeholder = "$t('cip.dc.DataAggregation.field.Aggregationsourcetable')"  class = 'new-form-input' v-model="form.sourceTable" size="mini" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.DataAggregation.field.Convergencedimension')">
              <el-input  :placeholder = "$t('cip.dc.DataAggregation.field.Convergencedimension')"  class = 'new-form-input' v-model="form.dimension" size="mini" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.DataAggregation.field.Convergencetime')" prop="sourceConfig">
              <el-input  :placeholder = "$t('cip.dc.DataAggregation.field.Convergencetime')"  class = 'new-form-input' v-model="form.sourceConfig" size="mini" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>




      </el-form>
    </div>
  </div>
</template>

<script>
import {dataConvergeListUp, dataSplitSel, dataSplitUp} from "@/api/dataAcquisition";

export default {
  name: "dataCoonfigAdd",
  data(){
    return {
      headBtnOptions: [{
        label: this.$t('cip.cmn.btn.saveBtn'),
        emit: "head-save",
        type: "button",
        icon: ""
      },
        {
          label: this.$t('cip.cmn.btn.saveBackBtn'),
          emit: "head-save-back",
          type: "button",
          icon: ""
        }, {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: ""
        }],
      rules: {
        aggCode: [
          { required: true, message: this.$t('cip.dc.DataAggregation.field.AggregationCoding'), trigger: 'change' }
        ],
        taskGroup: [
          { required: true, message: this.$t('cip.dc.DataAggregation.field.Taskgrouping'), trigger: 'change' }
        ],
        taskExecuteOrder: [
          { required: true, message: this.$t('cip.dc.DataAggregation.field.Executionorder'), trigger: 'change' }
        ],
        sourceTable: [
          { required: true, message: this.$t('cip.dc.DataAggregation.field.Aggregationsourcetable'), trigger: 'change' }
        ],
      },
      form: {
        "aggCode":"",
        "taskGroup":"",
        "taskExecuteOrder":"",
        "sourceTable":"",
        "dimension":"",
        "aggTime":""
      },
      formLabelWidth: '120px',
      selDat:[],
    }
  },
  created(){
    if(this.$route.query.id){
      this.form = JSON.parse(localStorage.getItem('conEditObj'));
      this.$route.meta.title = '数据汇聚配置编辑'
    }else{
      this.$route.meta.title = '数据汇聚配置添加'
    }
  },
  methods:{
    headSave(type){
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if(this.form.id){
            dataConvergeListUp(this.form).then(()=>{
              this.$message.success(this.$t('DataAggregation.msg'));
              if(type=='back'){
                this.headCancel()
              }
            })
          }else{
            dataConvergeListUp(this.form).then(()=>{
              this.$message.success(this.$t('DataAggregation.msg'));
              if(type=='back'){
                this.headCancel()
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },

  }
}
</script>

<style scoped>

</style>
